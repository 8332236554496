var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-plus"
                  },
                  on: { click: _vm.addShow }
                },
                [_vm._v(_vm._s(_vm.$t("ADD")))]
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("ADD"),
                visible: _vm.addVisible,
                fullscreen: "",
                "append-to-body": true
              },
              on: {
                "update:visible": function($event) {
                  _vm.addVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.rules,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("CODE"), prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.code,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "code", $$v)
                          },
                          expression: "addForm.code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("NAME"), prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "name", $$v)
                          },
                          expression: "addForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("DATATYPE"), prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.addRadioChange },
                          model: {
                            value: _vm.addForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "type", $$v)
                            },
                            expression: "addForm.type"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v(_vm._s(_vm.$t("NUMERICAL")))
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(_vm._s(_vm.$t("ENUMERATION")))
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v(_vm._s(_vm.$t("CHARACTER")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.numberShow,
                          expression: "numberShow"
                        }
                      ],
                      attrs: { label: _vm.$t("MAXIMUM") }
                    },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.addForm.maxValue,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "maxValue", $$v)
                          },
                          expression: "addForm.maxValue"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.numberShow,
                          expression: "numberShow"
                        }
                      ],
                      attrs: { label: _vm.$t("MINIMUM") }
                    },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.addForm.minValue,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "minValue", $$v)
                          },
                          expression: "addForm.minValue"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.eunmShow,
                          expression: "eunmShow"
                        }
                      ],
                      attrs: { label: _vm.$t("ENUMERATED VALUES") }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.addForm.enumerationSource,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "enumerationSource", $$v)
                            },
                            expression: "addForm.enumerationSource"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("自定义")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("计量单位")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("管圈颜色")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("物料档案")
                          ]),
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("物料分类")
                          ])
                        ],
                        1
                      ),
                      _vm.addForm.enumerationSource == 0
                        ? _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              placeholder: _vm.$t(
                                "PLEASE SELECT ENUMERATED VALUES"
                              )
                            },
                            model: {
                              value: _vm.textarea,
                              callback: function($$v) {
                                _vm.textarea = $$v
                              },
                              expression: "textarea"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.addVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("CANCEL")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addSubmit }
                    },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("Edit"),
                visible: _vm.editVisible,
                fullscreen: "",
                "append-to-body": true
              },
              on: {
                "update:visible": function($event) {
                  _vm.editVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.editForm,
                    rules: _vm.rules,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("CODE"), prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editForm.code,
                          callback: function($$v) {
                            _vm.$set(_vm.editForm, "code", $$v)
                          },
                          expression: "editForm.code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("NAME"), prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.editForm, "name", $$v)
                          },
                          expression: "editForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("DATATYPE"), prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.addRadioChange },
                          model: {
                            value: _vm.editForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.editForm, "type", $$v)
                            },
                            expression: "editForm.type"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v(_vm._s(_vm.$t("NUMERICAL")))
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(_vm._s(_vm.$t("ENUMERATION")))
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v(_vm._s(_vm.$t("CHARACTER")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.numberShow,
                          expression: "numberShow"
                        }
                      ],
                      attrs: { label: _vm.$t("MAXIMUM") }
                    },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.editForm.maxValue,
                          callback: function($$v) {
                            _vm.$set(_vm.editForm, "maxValue", $$v)
                          },
                          expression: "editForm.maxValue"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.numberShow,
                          expression: "numberShow"
                        }
                      ],
                      attrs: { label: _vm.$t("MINIMUM") }
                    },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.editForm.minValue,
                          callback: function($$v) {
                            _vm.$set(_vm.editForm, "minValue", $$v)
                          },
                          expression: "editForm.minValue"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.eunmShow,
                          expression: "eunmShow"
                        }
                      ],
                      attrs: { label: _vm.$t("ENUMERATED VALUES") }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.editForm.enumerationSource,
                            callback: function($$v) {
                              _vm.$set(_vm.editForm, "enumerationSource", $$v)
                            },
                            expression: "editForm.enumerationSource"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("自定义")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("计量单位")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("管圈颜色")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("物料档案")
                          ]),
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("物料分类")
                          ])
                        ],
                        1
                      ),
                      _vm.editForm.enumerationSource == 0
                        ? _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              placeholder: _vm.$t(
                                "PLEASE SELECT ENUMERATED VALUES"
                              )
                            },
                            model: {
                              value: _vm.editTextarea,
                              callback: function($$v) {
                                _vm.editTextarea = $$v
                              },
                              expression: "editTextarea"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.editVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("CANCEL")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.editSubmit }
                    },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "inactive-value": 1, "active-value": 0 },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "menu",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: {
                            type: "text",
                            size: "small",
                            icon: "el-icon-edit"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.editShow(scope.row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Edit")) + "\n        ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: {
                            type: "text",
                            size: "small",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handleDel(scope.row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Delete")) + "\n        ")]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c("el-input", {
                    staticStyle: {
                      display: "inlie-block",
                      width: "200px",
                      marginRight: "5px"
                    },
                    attrs: {
                      placeholder: _vm.$t(
                        "PLEASE ENTER ATTRIBUTE NAME OR CODE"
                      ),
                      size: "small"
                    },
                    model: {
                      value: _vm.nameOrCod,
                      callback: function($$v) {
                        _vm.nameOrCod = $$v
                      },
                      expression: "nameOrCod"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }