<template>
  <div>
    <basic-container>
      <div class="header">
        <el-button @click="addShow" type="primary" size="small" icon="el-icon-plus">{{ $t('ADD') }}</el-button>
      </div>

      <el-dialog :title="$t('ADD')"
                 :visible.sync="addVisible"
                 fullscreen
                 :append-to-body="true">
        <el-form :model="addForm" :rules="rules" label-width="100px" ref="ruleForm" class="demo-ruleForm">
          <el-form-item :label="$t('CODE')" prop="code">
            <el-input v-model="addForm.code"></el-input>
          </el-form-item>
          <el-form-item :label="$t('NAME')" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('DATATYPE')" prop="type">
            <el-radio-group v-model="addForm.type" @change="addRadioChange">
              <el-radio :label=0>{{ $t('NUMERICAL') }}</el-radio>
              <el-radio :label=1>{{ $t('ENUMERATION') }}</el-radio>
              <el-radio :label=2>{{ $t('CHARACTER') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('MAXIMUM')" v-show="numberShow">
            <el-input-number v-model="addForm.maxValue"></el-input-number>
          </el-form-item>
          <el-form-item :label="$t('MINIMUM')" v-show="numberShow">
            <el-input-number v-model="addForm.minValue"></el-input-number>
          </el-form-item>
          <el-form-item :label="$t('ENUMERATED VALUES')" v-show="eunmShow">
            <!--add -->
            <el-radio-group v-model="addForm.enumerationSource">
              <el-radio :label=0>自定义</el-radio>
              <el-radio :label=1>计量单位</el-radio>
              <el-radio :label=2>管圈颜色</el-radio>
              <el-radio :label=3>物料档案</el-radio>
              <el-radio :label=4>物料分类</el-radio>
            </el-radio-group>

            <el-input
                v-if="addForm.enumerationSource == 0"
                type="textarea"
                :rows="5"
                :placeholder="$t('PLEASE SELECT ENUMERATED VALUES')"
                v-model="textarea">
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addVisible = false">{{ $t('CANCEL') }}</el-button>
          <el-button type="primary" @click="addSubmit">{{ $t('Yes') }}</el-button>
        </div>
      </el-dialog>

      <el-dialog :title="$t('Edit')"
                 :visible.sync="editVisible"
                 fullscreen
                 :append-to-body="true">
        <el-form :model="editForm" :rules="rules" label-width="100px" ref="ruleForm" class="demo-ruleForm">
          <el-form-item :label="$t('CODE')" prop="code">
            <el-input v-model="editForm.code"></el-input>
          </el-form-item>
          <el-form-item :label="$t('NAME')" prop="name">
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('DATATYPE')" prop="type">
            <el-radio-group v-model="editForm.type" @change="addRadioChange">
              <el-radio :label=0>{{ $t('NUMERICAL') }}</el-radio>
              <el-radio :label=1>{{ $t('ENUMERATION') }}</el-radio>
              <el-radio :label=2>{{ $t('CHARACTER') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('MAXIMUM')" v-show="numberShow">
            <el-input-number v-model="editForm.maxValue"></el-input-number>
          </el-form-item>
          <el-form-item :label="$t('MINIMUM')" v-show="numberShow">
            <el-input-number v-model="editForm.minValue"></el-input-number>
          </el-form-item>
          <el-form-item :label="$t('ENUMERATED VALUES')" v-show="eunmShow">
            <!--add -->
            <el-radio-group v-model="editForm.enumerationSource">
              <el-radio :label=0>自定义</el-radio>
              <el-radio :label=1>计量单位</el-radio>
              <el-radio :label=2>管圈颜色</el-radio>
              <el-radio :label=3>物料档案</el-radio>
              <el-radio :label=4>物料分类</el-radio>
            </el-radio-group>

            <el-input
                v-if="editForm.enumerationSource == 0"
                type="textarea"
                :rows="5"
                :placeholder="$t('PLEASE SELECT ENUMERATED VALUES')"
                v-model="editTextarea">
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editVisible = false">{{ $t('CANCEL') }}</el-button>
          <el-button type="primary" @click="editSubmit">{{ $t('Yes') }}</el-button>
        </div>
      </el-dialog>

      <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-del="handleDel">
        <template slot="status" slot-scope="scope">
          <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                     @change="change(scope.row)">
          </el-switch>
        </template>
        <template slot="menuRight">
          <el-input v-model="nameOrCod" :placeholder="$t('PLEASE ENTER ATTRIBUTE NAME OR CODE')"
                    style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
        </template>
        <template slot="menu" scope="scope">
          <el-button type="text"
                     size="small"
                     icon="el-icon-edit"
                     class="none-border"
                     @click.stop="editShow(scope.row)">{{ $t("Edit") }}
          </el-button>
          <el-button type="text"
                     size="small"
                     icon="el-icon-delete"
                     class="none-border"
                     @click.stop="handleDel(scope.row)">{{ $t("Delete") }}
          </el-button>
        </template>
        <!-- <template slot="menu">
            <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">{{$t('DISABLE')}}</el-button>
        </template> -->
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list, add, edit, remove, audit} from "@/api/mtrDic";
import {getProps} from "@/api/config";

export default {
  data() {
    return {
      textarea: "",
      editTextarea: "",
      numberShow: false,
      eunmShow: false,
      charShow: false,
      addVisible: false,
      editVisible: false,
      tableLoading: false,
      addForm: {
        enumerationSource: null,
        code: null,
        name: null,
        type: null,
        maxValue: null,
        minValue: null,
        eunmList: [],
      },
      editForm: {
        enumerationSource: null,
        id: null,
        code: null,
        name: null,
        type: null,
        maxValue: null,
        minValue: null,
        eunmList: [],
      },
      rules: {
        code: [
          {required: true, message: this.$t('PLEASE ENTER THE CODE'), trigger: 'blur'},
        ],
        name: [
          {required: true, message: this.$t('PLEASE ENTER A NAME'), trigger: 'blur'},
        ],
        type: [
          {required: true, message: this.$t('PLEASE ENTER INVENTORY TYPE'), trigger: 'blur'},
        ]
      },
      selectionList: [],
      nameOrCod: "",
      status: "0",
      page: {
        pageSize: 20,
        pagerCount: 5,
        total: '',
        pageCurrent: 1
      },
      disabled: true,
      tableData: [],
      tableOption: {
        delBtn: false,
        addBtn: false,
        editBtn: false,
        menuWidth: 200,
        dialogWidth: 500,
        selection: true,
        tip: false,
        columnBtn: false,
        dialogFullscreen: true,
        addBtnText: this.$t('ADD'),
        editBtnText: this.$t('Edit'),
        delBtnText: this.$t('DELETE'),
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [{
          label: this.$t('CODE'),
          row: true,
          span: 24,
          prop: "code",
          rules: {
            required: true,
            message: this.$t('PLEASE ENTER THE CODE'),
            trigger: "blur"
          }
        }, {
          label: this.$t('NAME'),
          row: true,
          span: 24,
          prop: "name",
          rules: [{
            required: true,
            message: this.$t('PLEASE ENTER A NAME'),
            trigger: "blur"
          }]
        }, {
          label: this.$t('DATA STATUS'),
          addDisplay: false,
          editDisplay: false,
          prop: "isBase",
          hide: true,
          span: 24,
          type: "select",
          dicData: [{
            label: this.$t('True'),
            value: 1
          }, {
            label: this.$t('False'),
            value: 0
          }],
          rules: {
            required: true,
            message: this.$t('PLEASE ENTER BASE UOM'),
            trigger: "blur"
          }
        }, {
          label: this.$t('DATATYPE'),
          span: 24,
          prop: "type",
          formatter: (row) => {
            switch (row.type) {
              case 0:
                return this.$t('NUMERICAL');
              case 1:
                return this.$t('ENUMERATION');
              case 2:
                return this.$t('CHARACTER');
            }
            return "";
          }
        }]
      }
    }
  },
  created() {
    getProps().then(res => {
      this.tableOption.column[3].dicData = res.data.data;
    });
    list().then(res => {
      this.tableData = res.data.data.items;
      this.page.total = res.data.data.total;

    })
  },
  methods: {
    editShow(row) {
      console.log(row)
      this.editForm.id = row.id;
      this.editForm.code = row.code;
      this.editForm.name = row.name;
      this.editForm.type = row.type;
      this.editForm.enumerationSource = row.enumerationSource;
      this.editForm.maxValue = row.maxValue;
      this.editForm.minValue = row.minValue;
      switch (this.editForm.type) {
        case 0:
          this.numberShow = true;
          this.eunmShow = false;
          this.editForm.maxValue = row.maxValue;
          this.editForm.minValue = row.minValue;
          break;
        case 1:
          this.numberShow = false;
          this.eunmShow = true;
          break;
        case 2:
          this.numberShow = false;
          this.eunmShow = false;
          break;
      }
      this.editTextarea = "";
      if (row.eunmList) {
        row.eunmList.forEach(item => {
          this.editTextarea += item + "\n";
        })
      }
      this.editVisible = true;
    },
    editSubmit() {
      console.log( this.editForm)
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.editForm.type == 1 && this.editForm.enumerationSource == 0 && !this.editTextarea) {
            this.$message({
              type: "error",
              message: this.$t('ENUMERATED VALUES NOT NULL')
            });
            return;
          }
          if (this.editForm.type == 1 && this.editForm.enumerationSource == 0) {
            this.editForm.eunmList = this.editTextarea.split("\n");
          }
          edit(this.editForm).then((res) => {
            if (res.data.code == "0000") {
              this.$message({
                type: "success",
                message: this.$t('EDIT SUCCEEDED')
              });
            }
            this.list();
          });
          this.editVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    addShow() {
      this.addForm = {
        code: null,
        name: null,
        type: null,
        maxValue: null,
        minValue: null,
        eunmList: [],
      };
      this.textarea = "";
      this.addVisible = true;
      this.numberShow = false;
      this.eunmShow = false;
    },
    addSubmit(done) {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // alert('submit!');

          if (this.addForm.type == 1 && this.addForm.enumerationSource == 0 && !this.textarea) {
            this.$message({
              type: "error",
              message: this.$t('ENUMERATED VALUES NOT NULL')
            });
            return;
          }
          if (this.addForm.type == 1) {
            this.addForm.eunmList = this.textarea.split("\n");
          }
          console.log(this.addForm)
          add(this.addForm).then((res) => {
            if (res.data.code == "0000") {
              this.$message.success(this.$t("AddSuccess"))
            }
            this.list();
            done();
          })
          this.addVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    addRadioChange(value) {
      switch (value) {
        case 0:
          this.numberShow = true;
          this.eunmShow = false;
          break;
        case 1:
          this.numberShow = false;
          this.eunmShow = true;
          break;
        case 2:
          this.numberShow = false;
          this.eunmShow = false;
          break;
      }
    },
    currentChange(pageCurrent) {
      this.page.pageCurrent = pageCurrent;
      this.list();
    },
    sizeChange(pageSize) {
      this.page.pageCurrent = 1;
      this.page.pageSize = pageSize;
      this.list();
    },
    returnAudit() {
      audit(this.selectionList[0], 0).then(() => {
        this.$message({
          type: "success",
          message: this.$t('DE APPROVAL SUCCEEDED')
        });
        this.list();
      })
    },
    audit() {
      console.log("审核选择项", this.selectionList[0].id);
      audit(this.selectionList[0], 1).then(() => {
        this.$message({
          type: "success",
          message: this.$t('AUDIT SUCCEEDED')
        });
        this.list();
      });
    },
    handleDel(row) {
      this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        remove(row.id).then((res) => {
          if (res.data.code == "0000") {
            this.$message({
              type: "success",
              message: this.$t('DeleteSuccess'),
            });
          }
          this.list();
        })
      })
    },
    change(row) {
      changeStatus(row)
    },
    selectionChange(list) {
      this.selectionList = list;
      console.log(list);
      list != "" ? this.disabled = false : this.disabled = true;
    },
    refreshChange() {
      this.list();
    },
    list() {
      list(this.page.pageCurrent, this.page.pageSize, this.nameOrCod, this.status).then(res => {
        this.tableData = res.data.data.items;
        this.page.total = res.data.data.total;
        this.tableData.forEach(v => {
          v.status = v.status;
        })
      })
    },
    envText: function () {
      return this.env
    }
  }
}
</script>
<style scoped>
.el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  padding-left: 10px;
}
</style>
